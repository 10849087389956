<template>
	<el-container>
		<el-main>
			<el-card shadow="never">
				<el-row :gutter="10" class="top_l">
					<el-col :span="5" :xs="24"><el-input v-model="code" clearable placeholder="请输入编码"></el-input></el-col>
					<el-col :span="19" :xs="24" class="elel">
						<el-button type="primary" @click="gameShare">搜索</el-button>
						<el-button type="primary" style="margin-left: 20px;" @click="dialogAdd = true">新增</el-button>
						<el-button type="primary" style="margin-left: 20px;" @click="refreshCache">刷新缓存</el-button>
						<div class="readbox">
							<el-tag size="large">查询是否可用：</el-tag>
							<el-radio-group v-model="status" @change="radioes">
								<el-radio label="" border>全部</el-radio>
								<el-radio :label="1" border>是</el-radio>
								<el-radio :label="0" border>否</el-radio>
							</el-radio-group>
						</div>
					</el-col>
				</el-row>
				<!-- table 表格 -->
				<el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }" border
					stripe>
					<el-table-column prop="code" label="编码" width="400"></el-table-column>
					<el-table-column prop="value" label="值" width="500"></el-table-column>
					<el-table-column prop="comment" label="描述" width="400"></el-table-column>
					<el-table-column label="是否可用">
						<template v-slot="scope">
							<el-switch @change="switchClick(scope.row)" v-model="scope.row.status" class="mb-2"
								style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" :active-value="1"
								:inactive-value="0" active-text="是" inactive-text="否" />
						</template>
					</el-table-column>
					<el-table-column prop="createTime" label="创建时间"></el-table-column>
					<el-table-column prop="updateTime" label="更新时间"></el-table-column>
					<el-table-column label="操作" align="center" width="150">
						<template v-slot="scope">
							<el-button size="small" type="primary" plain @click="clcedit(scope.row)">编辑</el-button>
							<el-button size="small" type="primary" plain @click="clcdelete(scope.row)">删除</el-button>
						</template>
					</el-table-column>
					<template #empty>
						<el-empty :description="emptyText" :image-size="100"></el-empty>
					</template>
				</el-table>
				<!-- 分页 -->
				<div class="pagin">
					<el-pagination :small="true" v-model:currentPage="currentPage" v-model:page-size="pageSize"
						:page-sizes="[10, 20, 50, 100]" background layout="total, sizes, prev, pager, next, jumper"
						:total="+totals" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
				</div>
				<!-- 新增-配置 -->
				<el-dialog v-model="dialogAdd" title="新增配置" width="50%" :fullscreen="fullscreenshow">
					<el-form :model="ruleForm" :rules="rules" ref="addruleForm" label-width="70px" class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="编码" prop="code"><el-input placeholder="请输入编码"
										v-model="ruleForm.code"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="描述" prop="comment"><el-input placeholder="请输入描述"
										v-model="ruleForm.comment"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="值" prop="value"><el-input type="textarea" :rows="4" placeholder="请输入值"
										v-model="ruleForm.value"></el-input></el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="submitClick('addruleForm')">提交</el-button>
							<el-button @click="resetClick('addruleForm')">重置</el-button>
						</span>
					</template>
				</el-dialog>
				<!-- 修改-配置 -->
				<el-dialog v-model="dialogEdit" title="修改配置" width="50%" :fullscreen="fullscreenshow">
					<el-form :model="EditruleForm" :rules="rules" ref="EditruleFormRef" label-width="110px"
						class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="编码" prop="code"><el-input placeholder="请输入编码"
										v-model="EditruleForm.code"></el-input></el-form-item>
							</el-col>

							<el-col :span="12" :xs="24">
								<el-form-item label="描述" prop="comment"><el-input placeholder="请输入描述"
										v-model="EditruleForm.comment"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="值" prop="value">
									<el-input type="textarea" :rows="4" placeholder="请输入值"
										v-model="EditruleForm.value"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="EditsubmitForm('EditruleFormRef')">提交</el-button>
							<el-button @click="dialogEdit = false">取消</el-button>
						</span>
					</template>
				</el-dialog>
			</el-card>
		</el-main>
	</el-container>
</template>

<script>
export default {
	name: 'gameConfig',
	data() {
		return {
			fullscreenshow: false,
			emptyText: '暂无数据',
			currentPage: 1,
			pageSize: 10,
			tableData: [],
			totals: null,
			serloading: false,
			productId: '',
			code: '',
			status: '',
			// 新增配置
			dialogAdd: false,
			ruleForm: {
				code: '',
				value: '',
				comment: ''
			},
			rules: {
				code: [{ required: true, message: '请输入编码', trigger: 'blur' }],
				value: [{ required: true, message: '请输入值', trigger: 'blur' }],
				comment: [{ required: true, message: '请输入描述', trigger: 'blur' }]
			},
			// 编辑
			dialogEdit: false,
			EditruleForm: {
				id: '',
				code: '',
				value: '',
				comment: ''
			}
		};
	},
	created() {
		if (document.body.clientWidth < 768) {
			this.fullscreenshow = true;
		} else {
			this.fullscreenshow = false;
		}
		this.productId = this.$TOOL.data.get('DATA_SELECTEED');
		// 获取通用配置
		this.ConfigInfo();
	},
	watch: {
		'$store.state.dataSelected'(newval) {
			if (this.productId != newval) {
				this.productId = newval;
				// 获取通用配置
				this.ConfigInfo();
			}
		}
	},
	methods: {
		// 获取通用配置
		ConfigInfo() {
			this.serloading = true;
			this.$HTTP.post(
				'gameConfigInfo/query',
				{
					productId: this.productId,
					code: this.code,
					status: this.status,
					pageNumber: this.currentPage,
					pageSize: this.pageSize
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tableData = response.data.data.records;
							this.totals = response.data.data.total;
							this.serloading = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 重置
		resetClick(name) {
			this.$refs[name].resetFields();
		},
		// 搜索
		gameShare() {
			this.currentPage = 1;
			// 获取通用配置
			this.ConfigInfo();
		},
		// 刷新缓存
		refreshCache() {
			this.serloading = true;
			this.$HTTP.post('gameConfigInfo/refreshCache',
				{
					productId: this.productId,
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.serloading = false;
							this.$message.success(response.data.msg);
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				});
		},
		//查新是否可用
		radioes() {
			this.currentPage = 1;
			this.ConfigInfo();
		},
		// 是否可用
		switchClick(row) {
			this.$HTTP.post(
				'gameConfigInfo/updateStatus',
				{
					id: row.id,
					status: row.status,
					productId: this.productId,
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.$message.success(response.data.msg);
							// 获取配置
							this.ConfigInfo();
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 新增-配置
		submitClick(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$HTTP.post(
						'gameConfigInfo/add',
						{
							code: this.ruleForm.code,
							value: this.ruleForm.value,
							comment: this.ruleForm.comment,
							productId: this.productId,
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.dialogAdd = false;
									this.$message.success(response.data.msg);
									this.resetClick(formName);
									// 获取配置
									this.ConfigInfo();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				} else {
					return false;
				}
			});
		},
		// 编辑
		clcedit(row) {
			this.EditruleForm.code = row.code;
			this.EditruleForm.value = row.value;
			this.EditruleForm.comment = row.comment;
			this.EditruleForm.id = row.id;
			this.dialogEdit = true;
		},
		EditsubmitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$HTTP.post(
						'gameConfigInfo/update',
						{
							id: this.EditruleForm.id,
							code: this.EditruleForm.code,
							comment: this.EditruleForm.comment,
							value: this.EditruleForm.value,
							productId: this.productId,
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.dialogEdit = false;
									this.$message.success(response.data.msg);
									// 获取配置
									this.ConfigInfo();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				} else {
					return false;
				}
			});
		},
		// 删除
		clcdelete(row) {
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$HTTP.post(
					'gameConfigInfo/delete',
					{
						id: row.id,
						productId: this.productId,
					},
					response => {
						if (response.status >= 200 && response.status < 300) {
							if (response.data.code == 0) {
								this.$message.success(response.data.msg);
								// 获取通用配置
								this.ConfigInfo();
							} else {
								this.$message.error(response.data.msg);
							}
						} else {
							console.log(response.message);
						}
					}
				);
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});
			});
		},
		// 分页
		handleSizeChange(val) {
			this.currentPage = 1;
			this.pageSize = val;
			// 获取通用配置
			this.ConfigInfo();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			// 获取通用配置
			this.ConfigInfo();
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.readbox {
	display: flex;
	margin-left: 20px;
	align-items: center;

	span {
		margin-right: 10px;
		font-size: 16px;
	}
}

.elel {
	display: flex;
	align-items: center;
}
</style>
